import {
    CHANGE_ORDERS_PAGE,
    GET_ALL_ORDERS,
    GET_CATEGORIES,
    GET_NEW_QUOTE_COUNT,
    GET_ONE_ORDER,
    GET_ORDER_ANALYTICS,
    SEARCH_ORDERS,
    USER_LOGOUT,
} from '../types'

const initialState = {
    categories: null,
    orders: null,
    order: null,
    newOrderCount: 0,
    pages: '',
    page: '',
    count: '',
    keyword: '',
    orderAnalytics: null,
}

const orderReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categories: payload,
            }
        case GET_ALL_ORDERS:
            return {
                ...state,
                orders: payload.orders,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                customer: null,
            }
        case SEARCH_ORDERS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_ORDERS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_ORDER:
            return {
                ...state,
                order: payload,
            }
        case USER_LOGOUT:
            return {
                orders: null,
                order: null,
                pages: '',
                page: '',
                count: '',
                keyword: '',
            }
        case GET_NEW_QUOTE_COUNT:
            return {
                ...state,
                newOrderCount: payload,
            }
        case GET_ORDER_ANALYTICS:
            return {
                ...state,
                orderAnalytics: payload,
            }
        default:
            return state
    }
}

export default orderReducer
