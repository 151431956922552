import { Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import Main from './pages/Main'
import Roles from './pages/Roles'
import LoadingSet from './components/Loading'
import Alert from './components/Alert'
import Users from './pages/Users'
import AddNewUser from './pages/AddNewUser'
import User from './pages/User'
import Suppliers from './pages/supplier/Suppliers'
import AddSupplier from './pages/supplier/AddSupplier'
import EditSupplier from './pages/supplier/EditSupplier'
import Logs from './pages/Logs'
import Items from './pages/item/Items'
import Item from './pages/item/Item'
import AddItem from './pages/item/AddItem'
import EditItem from './pages/item/EditItem'
import ProductCategory from './pages/ProductCategory'
import DoorCategory from './pages/DoorCategory'
import Products from './pages/product/Products'
import AddProduct from './pages/product/AddProduct'
import EditProduct from './pages/product/EditProduct'
import Product from './pages/product/Product'
import ProductColors from './pages/ProductColors'
import Customers from './pages/Customers'
import Customer from './pages/Customer'
import Warehouses from './pages/Warehouses'
import Orders from './pages/order/Orders'
import Order from './pages/order/Order'
import OrderEdit from './pages/order/OrderEdit'
import BaseTaxes from './pages/BaseTaxes'
import PrintInvoice from './pages/order/PrintInvoice'
import PurchaseOrders from './pages/purchaseOrder/PurchaseOrders'
import AddPurchaseOrder from './pages/purchaseOrder/AddPurchaseOrder'
import EditPurchaseOrder from './pages/purchaseOrder/EditPurchaseOrder'
import PurchaseOrder from './pages/purchaseOrder/PurchaseOrder'
import AddShipment from './pages/purchaseOrder/AddShipment'
import Shipments from './pages/shipment/Shipments'
import Shipment from './pages/shipment/Shipment'
import DefectiveItems from './pages/defectiveItem/DefectiveItems'
import PrintQuotation from './pages/order/PrintQuotation'
import OrderPayments from './pages/orderPayment/OrderPayments'
import OrderPayment from './pages/orderPayment/OrderPayment'
import OrderShipments from './pages/orderShipment/OrderShipments'
import OrderShipment from './pages/orderShipment/OrderShipment'
import Warehouse from './pages/warehouse/Warehouse'
import PrintItems from './pages/orderShipment/PrintItems'
import ItemRecords from './pages/itemRecord/ItemRecords'
import PrintAssembly from './pages/orderShipment/PrintAssembly'
import PrintPackingList from './pages/orderShipment/PrintPackingList'
import ProductOrders from './pages/order/ProductOrders'
import OrderAnalytics from './pages/order/OrderAnalytics'
import ItemAnalytics from './pages/item/ItemAnalytics'

function App() {
    return (
        <>
            <Alert />
            <LoadingSet />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/roles" element={<Roles />} />
                <Route path="/users" element={<Users />} />
                <Route path="/logs" element={<Logs />} />
                <Route path="/user/:id" element={<User />} />
                <Route path="/addnewuser" element={<AddNewUser />} />
                <Route path="/suppliers" element={<Suppliers />} />
                <Route path="/addsupplier" element={<AddSupplier />} />
                <Route
                    path="/editsupplier/:id/:formTypeStatus"
                    element={<EditSupplier />}
                />
                <Route
                    path="/supplier/:id/:formTypeStatus"
                    element={<EditSupplier />}
                />
                <Route path="/items" element={<Items />} />
                <Route path="/itemrecords" element={<ItemRecords />} />
                <Route path="/item/:id" element={<Item />} />
                <Route path="/additem" element={<AddItem />} />
                <Route path="/edititem/:id" element={<EditItem />} />
                <Route path="/productcategory" element={<ProductCategory />} />
                <Route path="/doorcategory" element={<DoorCategory />} />
                <Route path="login" element={<Login />} />
                <Route path="/products" element={<Products />} />
                <Route path="/product/:id" element={<Product />} />
                <Route path="/addproduct" element={<AddProduct />} />
                <Route path="/editproduct/:id" element={<EditProduct />} />
                <Route path="/productcolors" element={<ProductColors />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/warehouses" element={<Warehouses />} />
                <Route path="/warehouse/:id" element={<Warehouse />} />
                <Route path="/customer/:id" element={<Customer />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orderanalytics" element={<OrderAnalytics />} />
                <Route path="/itemanalytics" element={<ItemAnalytics />} />
                <Route path="/order/:id" element={<Order />} />
                <Route path="/productorders/:id" element={<ProductOrders />} />
                <Route path="/orderedit/:id" element={<OrderEdit />} />
                <Route path="/printinvoice/:id" element={<PrintInvoice />} />
                <Route
                    path="/printquotation/:id"
                    element={<PrintQuotation />}
                />
                <Route path="/printitems/:id" element={<PrintItems />} />
                <Route path="/printassembly/:id" element={<PrintAssembly />} />
                <Route
                    path="/printpackinglist/:id"
                    element={<PrintPackingList />}
                />
                <Route path="/basetaxes" element={<BaseTaxes />} />
                <Route path="/purchaseorders" element={<PurchaseOrders />} />
                <Route path="/purchaseorder/:id" element={<PurchaseOrder />} />
                <Route path="/addshipment/:id" element={<AddShipment />} />
                <Route
                    path="/addpurchaseorder"
                    element={<AddPurchaseOrder />}
                />
                <Route
                    path="/editpurchaseorder"
                    element={<EditPurchaseOrder />}
                />
                <Route path="/shipments" element={<Shipments />} />
                <Route path="/shipment/:id" element={<Shipment />} />
                <Route path="/defectiveitems" element={<DefectiveItems />} />
                <Route path="/orderpayments" element={<OrderPayments />} />
                <Route path="/orderpayment/:id" element={<OrderPayment />} />
                <Route path="/ordershipments" element={<OrderShipments />} />
                <Route path="/ordershipment/:id" element={<OrderShipment />} />
            </Routes>
        </>
    )
}

export default App
